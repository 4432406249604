import React, { PureComponent } from 'react';
import { Form, Input, DatePicker, Radio } from 'antd';
import dayjs from 'dayjs';
import { FormInstance } from 'antd/lib/form';
import moment from 'moment';
import { Layout } from '@/components';

export default class ActivityForm extends PureComponent {
  
  private form = React.createRef<FormInstance>()

  render() {

    return <div style={{background: '#fff', padding: '0 16px'}}>
      <Layout.Title icon="icon-info">活动信息</Layout.Title>
      <Form ref={this.form} labelCol={{span: 4}} style={{width: 400}}>
        <Form.Item label="活动名称" name="activityName"
          rules={[{
            required: true,
            message: '请填写活动名称',
          }]}>
          <Input placeholder="填写" />
        </Form.Item>
        <Form.Item label="开始时间" name="startTime"
          required
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value) return Promise.reject(new Error('请选择开始时间'));
                if (dayjs().endOf('minute').isAfter(value.endOf('minute')) || dayjs().endOf('minute').isSame(value.endOf('minute'))) return Promise.reject(new Error('开始时间必须大于当前时间!'));
                return Promise.resolve();
              },
            })
          ]}>
          <DatePicker
            disabledDate={disabledDate}
            style={{width: '100%'}}
            placeholder="选择"
            showTime={{
              hideDisabledOptions: true,
              defaultValue: (dayjs().add(1, 'hour').startOf('hour') as moment.Moment),
              format: 'HH:mm'
            }}
            format="YYYY-MM-DD HH:mm"
          />
        </Form.Item>
        <Form.Item label="结束时间" name="endTime"
          rules={[{
            required: true,
            message: '请选择结束时间',
          }, ({ getFieldValue }) => ({
            validator(_, value) {
              if (getFieldValue('startTime')) {
                if (getFieldValue('startTime').isAfter(value) || getFieldValue('startTime').isSame(value)) return Promise.reject(new Error('结束时间必须大于开始时间!'));
              }
              return Promise.resolve();
            },
          }),]}>
          <DatePicker
            disabledDate={disabledDate}
            style={{width: '100%'}}
            placeholder="选择"
            showTime={{
              hideDisabledOptions: true,
              defaultValue: (dayjs('23:59', 'HH:mm') as moment.Moment),
              format: 'HH:mm'
            }}
            format="YYYY-MM-DD HH:mm"
          />
        </Form.Item>
    </Form>
      
    </div>
  }

  public getForm = (): FormInstance => {
    return (this.form.current as FormInstance);
  }
}

function range(start, end) {
  const result: any = [];
  for (let i = start; i < end; i += 1) {
    result.push(i);
  }
  return result;
}

function disabledDate(current) {
  // Can not select days before today and today
  return current && current < dayjs().startOf('day');
}

function disabledRangeTime(_, type) {
  if (type === 'start') {
    return {
      disabledHours: () => range(0, dayjs().hour()),
      disabledMinutes: () => [],
      disabledSeconds: () => [],
    };
  }
  return {
    disabledHours: () => [],
    disabledMinutes: () => [],
    disabledSeconds: () => [],
  };
}
