import React, { PureComponent } from 'react';
import { Checkbox, Input, message, Form, Radio, Select, Tooltip, Space } from 'antd';
import { Dialog, InputNumber, ProductDialog, EditTable, Layout, Button } from '@/components';
import BigNumber from 'bignumber.js';
import apis from '@/api/api';
import ActivityForm from './ActivityForm';
import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';

interface IGiftProductParam {
  fullNumber: string
  giftCount: number
  giftProductName: string
  giftProductId: string
  giftSkuId: string
  giftSkuName: string
}
export default class ActivityDiscountCreate extends PureComponent<{
  activityType:IActivityType;
  history: any;
}> {
  
  private form = React.createRef<ActivityForm>()
  private product = React.createRef<ProductDialog>()

  private withCoupon: number = 0
  state: {
    giftModal: boolean
    dataSource: {
      productId?: string
      categoryName?: string
      productName?: string
      giftCondition?: 'COUNT' | 'AMOUNT'
      giftMethod?: 'LADDER' | 'CIRCULATE'
      giftRule?: 'SELF' | 'OTHER'
      giftAlgorithm?: 'ONCE' |'TOTAL'
      giftProducts: IGiftProductParam[]
    }[];
    productMode: 'row' | 'gift';
    expandedRowKeys: string[],
  } = {
    giftModal: false,
    dataSource: [],
    expandedRowKeys: [],
    productMode: 'row'
  }

  getColumns = (): any[] => {
    if (this.props.activityType === 'DISCOUNT') return this.getDiscountColumns();
    if (this.props.activityType === 'SPECIAL') return this.getSpecialColumns();
    if (this.props.activityType === 'GIFT') return this.getGiftColumns();
    return [];
  }

  getDiscountColumns = () => {
    return [{
      title: '打折（例:9.0是9折）',
      dataIndex: 'originalPrice',
      titleAlign: 'left',
      width: 'auto',
      render: (v, row, index) => {
        if (!row.productId) return null;
        return <Input className="app-input-text-align-right" style={{textAlign: 'right', width: 150}} prefix="打" suffix="折" value={row.discount} onChange={e => {
          const value = e.target.value;
          if (isNaN(Number(value))) return;
          const array = value.split('.');
          if (array.length > 2) return;
          if (array[1] && array[1].length > 1) return;
          if (Number(value) > 10) return;
          const next: any = [...this.state.dataSource];
          next[index].discount = value;
          this.setState({
            dataSource: next
          });
        }} onBlur={() => {
          const next: any = [...this.state.dataSource];
          const value = next[index].discount;
          next[index].discount = value ? new BigNumber(value).toFormat(1) : '10.0';
          this.setState({
            dataSource: next
          });
        }}/>
      }
    }];
  }

  getSpecialColumns = () => {
    return [
      {
        title: '规格',
        dataIndex: 'skuName',
        width: 300,
      },
      {
        title: '原价',
        dataIndex: 'originalPrice',
        align: 'right',
        width: 150,
      },
      {
        title: '特价',
        dataIndex: 'price',
        width: 150,
        align: 'center',
        render: (v, row, index) => {
          if (!row.productId) return null;
          return <InputNumber.Price value={row.price} onChange={value => {
            const next: any = [...this.state.dataSource];
            next[index].price = value;
            this.setState({
              dataSource: next
            });
          }}/>
        }
      },
      // {
      //   title: <span>
      //     活动限量
      //     <Tooltip title="非必填，填写后活动期间，销量达到限量值时将会恢复原价"><QuestionCircleOutlined/></Tooltip>
      //   </span>,
      //   width: 100,
      //   align: 'center',
      //   render: (v, row, index) => {
      //     if (!row.skuId) return null;
      //     const obj = {
      //       children: <InputNumber.Count style={{width: '100%', minWidth: 'none'}} onChange={value => {
      //         this.setState({

      //         });
      //       }}/>,
      //       props: {
      //         rowSpan: 1,
      //       },
      //     };
      //     if (index === 0 || (this.state.dataSource[index - 1] as any).productId !== row.productId) {
      //       obj.props.rowSpan = row.skuLength;
      //       return obj;
      //     }
      //     if ((this.state.dataSource[index - 1] as any).productId === row.productId) {
      //       obj.props.rowSpan = 0;
      //       return obj;
      //     }
      //     return obj;
      //   }
      // },
      // {
      //   title: <span>
      //     客户限购
      //     <Tooltip title="非必填，填写后活动期间，将会限制每个客户最多购买数量" placement="topLeft"><QuestionCircleOutlined/></Tooltip>
      //   </span>,
      //   width: 100,
      //   align: 'center',
      //   render: (v, row, index) => {
      //     if (!row.skuId) return null;
      //     const obj = {
      //       children: <InputNumber.Count style={{width: '100%', minWidth: 'none'}} onChange={v => {

      //       }}/>,
      //       props: {
      //         rowSpan: 1,
      //       },
      //     };
      //     if (index === 0 || (this.state.dataSource[index - 1] as any).productId !== row.productId) {
      //       obj.props.rowSpan = row.skuLength;
      //       return obj;
      //     }
      //     if ((this.state.dataSource[index - 1] as any).productId === row.productId) {
      //       obj.props.rowSpan = 0;
      //       return obj;
      //     }
      //     return obj;
      //   }
      // },
    ];
  }

  getGiftColumns = () => {
    return [
      {
        title: '满赠规则',
        width: 120,
        align: 'center',
        render: (v, row, index) => {
          if (!row.productId) return null;
          return <Select style={{width: '100%'}} value={row.giftCondition} onChange={v => {
            const next: any = [...this.state.dataSource];
            next[index].giftCondition = v;
            next[index].giftProducts = next[index].giftProducts.map(item => {
              return {
                ...item,
                fullNumber: '0',
              };
            })
            this.setState({
              dataSource: next
            });
          }}>
            <Select.Option value="COUNT">按购买数量</Select.Option>
            <Select.Option value="AMOUNT">按购买金额</Select.Option>
          </Select>
        }
      },
      {
        title: '赠送方式',
        width: 120,
        align: 'center',
        render: (v, row, index) => {
          if (!row.productId) return null;
          return <Select style={{width: '100%'}} value={row.giftMethod} onChange={v => {
            const next: any = [...this.state.dataSource];
            next[index].giftMethod = v;
            if (v === 'CIRCULATE') {
              // 从阶梯满赠改为循环满赠的时候只保留第一个赠品
              next[index].giftProducts.length = 1;
            }
            this.setState({
              dataSource: next
            });
          }}>
            <Select.Option value="CIRCULATE">循环满赠</Select.Option>
            <Select.Option value="LADDER">阶梯满赠</Select.Option>
          </Select>
        }
      },
      {
        title: '赠品规则',
        width: 120,
        align: 'center',
        render: (v, row, index) => {
          if (!row.productId) return null;
          return <Select style={{width: '100%'}} value={row.giftRule} onChange={v => {
            const next: any = [...this.state.dataSource];
            next[index].giftRule = v;
            next[index].giftProducts = [{
              fullNumber: '0',
              giftCount: 1,
              giftProductName: v === 'SELF' ? row.productName : '',
              giftProductId: v === 'SELF' ? row.productId : '',
              skuList: v === 'SELF' ? row.skuList : [],
              giftSkuId: v === 'SELF' ? row.skuList[0]?.skuId : undefined,
              giftSkuName: v === 'SELF' ? row.skuList[0]?.skuName : undefined,
            }];
            this.setState({
              dataSource: next
            });
          }}>
            <Select.Option value="SELF">赠送原品</Select.Option>
            <Select.Option value="OTHER">指定赠品</Select.Option>
          </Select>
        }
      },
      {
        title: '计算规则',
        width: 120,
        align: 'center',
        render: (v, row, index) => {
          if (!row.productId) return null;
          return <Select style={{width: '100%'}} value={row.giftAlgorithm} onChange={v => {
            const next: any = [...this.state.dataSource];
            next[index].giftAlgorithm = v;
            this.setState({
              dataSource: next
            });
          }}>
            <Select.Option value="ONCE">单次</Select.Option>
            <Select.Option value="TOTAL">累计</Select.Option>
          </Select>
        }
      },
    ];
  };

  render() {
    const { dataSource } = this.state;

    return (<Layout.Page>
      <Layout flex1 scrollY>
        <div style={{background: '#fff', padding: 16}}>
          <ActivityForm ref={this.form}/>
        </div>

        <div className="flex-columns" style={{marginTop: 10, padding: '16px 32px', background: '#fff', flex: 1, overflow: 'hidden'}}>
          <Layout.Title icon="icon-cuxiao">活动规则</Layout.Title>
          <Form.Item label="活动商品" required style={{marginBottom: 0}}> </Form.Item>
          <EditTable
            dataSource={dataSource}
            rowKey={(row: any) => row.skuId || row.id}
            onAdd={() => {
              this.setState({
                productMode: 'row'
              }, () => {
                this.product.current?.open();
              });
            }}
            onMinus={(row, index) => {
              this.clickDeleteTableRow(index, row);
            }}
            columns={[
              // {
              //   title: '序号',
              //   width: 65,
              //   dataIndex: 'indexColumn',
              //   align: 'center',
              //   fixed: 'left',
              //   disabledEmptyRender: true,
              //   render: (v, row, index) => {
              //     const value = index + 1;
              //     if (this.props.activityType != 'SPECIAL') return value;
              //     const obj = {
              //       children: value,
              //       props: {
              //         rowSpan: 1,
              //       },
              //     };
              //     if (index === 0 || (this.state.dataSource[index - 1] as any).productId !== row.productId) {
              //       obj.props.rowSpan = row.skuLength;
              //       return obj;
              //     }
              //     if ((this.state.dataSource[index - 1] as any).productId === row.productId) {
              //       obj.props.rowSpan = 0;
              //       return obj;
              //     }
              //     return obj;
              //   },
              // },
              {
                title: '商品编号',
                dataIndex: 'productId',
                align: 'center',
                width: 160,
                // render: (value, row, index) => {
                //   if (this.props.activityType != 'SPECIAL') return value;
                //   const obj = {
                //     children: value,
                //     props: {
                //       rowSpan: 1,
                //     },
                //   };
                //   if (index === 0 || (this.state.dataSource[index - 1] as any).productId !== row.productId) {
                //     obj.props.rowSpan = row.skuLength;
                //     return obj;
                //   }
                //   if ((this.state.dataSource[index - 1] as any).productId === row.productId) {
                //     obj.props.rowSpan = 0;
                //     return obj;
                //   }
                //   return obj;
                // }
              },
              {
                title: '商品名称',
                dataIndex: 'productName',
                width: 260,
                // render: (value, row, index) => {
                //   if (this.props.activityType != 'SPECIAL') return value;
                //   const obj = {
                //     children: value,
                //     props: {
                //       rowSpan: 1,
                //     },
                //   };
                //   if (index === 0 || (this.state.dataSource[index - 1] as any).productId !== row.productId) {
                //     obj.props.rowSpan = row.skuLength;
                //     return obj;
                //   }
                //   if ((this.state.dataSource[index - 1] as any).productId === row.productId) {
                //     obj.props.rowSpan = 0;
                //     return obj;
                //   }
                //   return obj;
                // }
              },
              ...this.getColumns(),
              // {
              //   title: '商品分类',
              //   dataIndex: 'categoryName',
              //   width: 200,
              // },
            ]}
            expandable={this.props.activityType === 'GIFT' ? {
              expandedRowRender: (row, rowIndex) => {
                if (!row.productId) return null;
                const giftProducts: IGiftProductParam[] = row.giftProducts;
                return <div>
                  {
                      giftProducts.map((item: IGiftProductParam, index: number) => {
                      const unit = row.unitList?.find(u => u.ratio === 1) || {};
                      return <div key={index} style={{paddingLeft: 19}}>
                        <Form.Item label="赠送规则" required>
                          <div>
                            <span style={{marginRight: 10}}>该商品，购买数量每满</span>
                            {
                              row.giftCondition === 'COUNT' &&
                              <InputNumber.Count selected value={item.fullNumber} style={{width: 100, marginRight: 20}} min={0} onChange={v => {
                                if (isNaN(v as any)) return;
                                const next = [...this.state.dataSource];
                                next[rowIndex].giftProducts[index].fullNumber = v;
                                this.setState({
                                  dataSource: next,
                                });
                              }}/>
                            }
                            {
                              row.giftCondition === 'AMOUNT' &&
                              <InputNumber.Price selected value={item.fullNumber} style={{width: 100, marginRight: 20}} min={0} onChange={v => {
                                if (isNaN(v as any)) return;
                                const next = [...this.state.dataSource];
                                next[rowIndex].giftProducts[index].fullNumber = v as any;
                                this.setState({
                                  dataSource: next,
                                });
                              }}/>
                            }
                            
                            {
                              row.giftCondition === 'COUNT' ? unit.unitName || '最小计量单位' : '元'
                            }，都会赠送：
                              商品 <Select
                                value={item.giftProductId || undefined}
                                placeholder="选择商品" 
                                open={false}
                                disabled={row.giftRule === 'SELF'}
                                style={{width: 200, marginRight: 20}}
                                onClick={() => {
                                  if (row.giftRule === 'SELF') return;
                                  this.setState({
                                    productMode: 'gift'
                                  }, () => {
                                    this.product.current?.open();
                                  });
                                }}
                              >
                                {
                                  item.giftProductId && <Select.Option value={item.giftProductId}>{item.giftProductName}</Select.Option>
                                }
                              </Select>
                              规格 <Select placeholder="选择规格" value={item.giftSkuId} style={{width: 200, marginRight: 20}} onChange={(value, option) => {
                                if (!value) return;
                                const next = [...this.state.dataSource];
                                next[rowIndex].giftProducts[index]['giftSkuId'] = value as string;
                                next[rowIndex].giftProducts[index]['giftSkuName'] = option['children'] as string;
                                this.setState({
                                  dataSource: next,
                                });
                              }}>
                                {
                                  (item['skuList'] || []).map(sku => {
                                    return <Select.Option key={sku.skuId} value={sku.skuId}>{sku.skuName}</Select.Option>
                                  })
                                }
                              </Select>
                            <InputNumber.Count selected style={{width: 100}} min={1} value={item.giftCount} onChange={v => {
                              const next = [...this.state.dataSource];
                              next[rowIndex].giftProducts[index].giftCount = v as any;
                              this.setState({
                                dataSource: next,
                              });
                            }}/>
                            （{unit.unitName || '最小计量单位'}）
                            {
                              row.giftProducts.length > 1 && <Button.Delete onClick={() => {
                                const next: any = [...this.state.dataSource];
                                next[rowIndex].giftProducts.splice(index, 1);
                                this.setState({
                                  dataSource: next,
                                });
                              }}>删除</Button.Delete>
                            }
                          </div>
                        </Form.Item>
                      </div>
                    })
                  }
                  {
                    row.giftMethod === 'LADDER' && <Button.Create onClick={() => {
                      this.clickAddGiftBtn(rowIndex);
                    }}>添加赠送规则</Button.Create>
                  }
                </div>
              },
              expandIcon: ({expanded, record}) => {
                if (expanded) return <Tooltip title="收起">
                  <Button disabled={!record.productId} type="link" icon={<MinusSquareOutlined/>} onClick={() => {
                    this.setState((state: any) => ({
                      expandedRowKeys: state.expandedRowKeys.filter(i => i !== record.productId),
                    }));
                  }}></Button>
                </Tooltip>
                return <Tooltip title="展开">
                  <Button type="link" disabled={!record.productId} icon={<PlusSquareOutlined/>} onClick={() => {
                    this.setState((state: any) => ({
                      expandedRowKeys: [...state.expandedRowKeys, record.productId]
                    }));
                  }}></Button>
                </Tooltip>
              },
              // expandRowByClick: true,
              expandedRowKeys: this.state.expandedRowKeys,
              onExpand: (expanded, row: any) => {
                if (expanded) {
                  this.setState((state: any) => ({
                    expandedRowKeys: [...state.expandedRowKeys, row.productId]
                  }));
                } else {
                  this.setState((state: any) => ({
                    expandedRowKeys: state.expandedRowKeys.filter(i => i !== row.productId),
                  }));
                }
              },
              rowExpandable: (row) => !!row.productId,
            } : {}}
            // actionColSpan={(v, row, index) => {
            //   const props = {
            //     rowSpan: 1,
            //   };
            //   if (index === 0 || (this.state.dataSource[index - 1] as any).productId !== row.productId) {
            //     props.rowSpan = row.skuLength;
            //     return props;
            //   }
            //   if ((this.state.dataSource[index - 1] as any).productId === row.productId) {
            //     props.rowSpan = 0;
            //     return props;
            //   }
            //   return props;
            // }}
          />
          
          {
            this.props.activityType === 'GIFT' &&
            <Form>
              <Form.Item label="规则说明" required style={{marginTop: 24, marginBottom: 0}}> </Form.Item>
              {/* <Form.Item label="满赠规则" required style={{marginTop: 24, marginBottom: 0}} name="giftCondition">
                <Radio.Group>
                  <Radio value="ladder">按购买数量</Radio>
                  <Radio value="circulate">按购买金额</Radio>
                </Radio.Group>
              </Form.Item> */}
              <Form.Item label="赠送规则"  name="giftMethod" style={{marginLeft: 34, marginBottom: 0}}>
                <div style={{paddingTop: 7}}>
                  <p style={{marginBottom: 5}}>1、阶梯满赠（如：买 10 箱送 1 箱、买 15 箱送 3 箱）</p>
                  <p>2、循环满赠（如：每买 10 箱送 1 箱、那么当客户购买 100 箱时，送 10 箱）</p>
                </div>
              </Form.Item>
              <Form.Item label="赠品规则" style={{marginLeft: 34}} name="giftRule">
                <div style={{paddingTop: 7}}>
                  <p style={{marginBottom: 5}}>1、赠送原品（如：买 10 箱 A 商品，送 1 箱 A 商品）</p>
                  <p>2、指定赠品（如：购买 10 箱 A 商品、送 1 箱 B 商品）</p>
                </div>
              </Form.Item>
            </Form>
          }
          <div style={{marginTop: 16}}>
            <span style={{marginRight: 16}}>不能同时使用</span>
            <Checkbox defaultChecked onChange={e => this.withCoupon = e.target.checked ? 0 : 1}>优惠券</Checkbox>
          </div>
          <ProductDialog
            ref={this.product}
            onOk={this.clickProductSelectOk}
            rowSelectType={this.state.productMode === 'gift' ? 'radio' : 'checkbox'}
            getRowDisabled={(row) => this.state.productMode === 'gift' ? false : !!row.activity}/>
        </div>
      </Layout>
      <Layout.FootBar>
        <Space>
          <Button type="primary" onClick={this.clickSave}>保存</Button>
          <Button onClick={this.clickSaveAndCreate}>保存并新增</Button>
        </Space>
      </Layout.FootBar>
    </Layout.Page>);
  }

  // 删除商品
  private clickDeleteTableRow = (index, row) => {
    if (this.props.activityType === 'SPECIAL') {
      const dataSource = this.state.dataSource.filter((d: any) => d.productId !== row.productId);
      this.setState({
        dataSource,
      });
    } else {
      const dataSource = [ ...this.state.dataSource ];
      dataSource.splice(index, 1);
      this.setState({
        dataSource,
      });
    }
  }

  // 选择商品确定
  private clickProductSelectOk = (products, params) => {
    if (this.state.productMode === 'gift') {
      if (products.length > 0) {
        const { rowIndex, index } = params;
        const next = [...this.state.dataSource];
        next[rowIndex].giftProducts[index].giftProductId = products[0].productId;
        next[rowIndex].giftProducts[index].giftProductName = products[0].productName;
        next[rowIndex].giftProducts[index].giftSkuId = products[0].skuList[0]?.skuId;
        next[rowIndex].giftProducts[index].giftSkuName = products[0].skuList[0]?.skuName;
        next[rowIndex].giftProducts[index]['skuList'] = products[0].skuList;
        // next[rowIndex].giftProducts.splice(index, 1, ...products.map(p => ({
        //   productId: p.productId,
        //   productName: p.productName,
        //   fullNumber: next[rowIndex].giftProducts[index].fullNumber || '',
        //   giftCount: next[rowIndex].giftProducts[index].giftCount || '1'
        // })));
        this.setState({
          dataSource: next,
        });
      }
      return;
    }
    // 去重
    const addProducts = products.filter(p => !this.state.dataSource.find((i: any) => i.productId === p.productId));
    if (addProducts.length === 0) return ;
    this.setState({
      visible: false,
      expandedRowKeys: this.state.expandedRowKeys.concat(addProducts.map(p => p.productId)),
      dataSource: this.state.dataSource.concat(addProducts.map((p: any) => {
        if (this.props.activityType === 'DISCOUNT') {
          p.discount = '10.0';
          p.limitBuyCount = 0;
          p.limitSaleCount = 0;
        }
        if (this.props.activityType === 'SPECIAL') {
          const skuLength = p.skuList.length;
          p = p.skuList.map(s => ({
            ...s,
            productId: p.productId,
            productName: p.productName,
            categoryName: p.categoryName,
            limitBuyCount: 0,
            limitSaleCount: 0,
            skuLength,
          }));
        }
        if (this.props.activityType === 'GIFT') {
          // 默认按数量
          p.giftCondition = 'COUNT';
          // 默认循环满赠
          p.giftMethod = 'CIRCULATE';
          // 默认单次计算
          p.giftAlgorithm = 'ONCE';
          // 默认赠送原品
          p.giftRule = 'SELF';
          // 默认赠品
          p.giftProducts = [{
            fullNumber: '0',
            giftCount: 1,
            giftProductName: p.productName,
            giftProductId: p.productId,
            giftSkuId: p.skuList ? p.skuList[0]?.skuId : undefined, // 默认第一个规格
            giftSkuName: p.skuList ? p.skuList[0]?.skuName : undefined, // 默认第一个规格
            skuList: p.skuList
          }];
        }
        return p;
      }).flat()),
    });
  }

  // 点击添加赠品规则
  private clickAddGiftBtn = (index: number) => {
    const dataSource = [...this.state.dataSource];
    const item: any = dataSource[index];
    item.giftProducts = [...item.giftProducts, {
      fullNumber: '0',
      giftCount: 1,
      giftProductName: item.giftRule === 'SELF' ? item.productName : '',
      giftProductId: item.giftRule === 'SELF' ? item.productId : '',
      giftSkuId: item.giftRule === 'SELF' ? item.skuList[0]?.skuId : undefined,
      giftSkuName: item.giftRule === 'SELF' ? item.skuList[0]?.skuName : undefined,
      skuList: item.skuList || [],
    }];
    this.setState({
      giftModal: true,
      dataSource,
    })
  }

  public resetFields = () => {
    this.setState({
      dataSource: [],
    });
    this.form.current?.getForm().resetFields();
  }

  // 验证
  public validate = async (callback) => {
    let values;
    try {
      values = await this.form.current?.getForm().validateFields();
    } catch (e) {
      console.log(e);
      return;
    }

    const { dataSource } = this.state;
    if (dataSource.length === 0) {
      message.warning('请添加商品');
      return;
    }

    let rules: any[] = [];
    let api;

    // 满赠活动验证赠品
    if (this.props.activityType === 'GIFT') {
      api = apis['activity.gift.create']
      rules = this.state.dataSource.map(row => {
        return {
          productId: row.productId,
          productName: row.productName,
          giftCondition: row.giftCondition,
          giftMethod: row.giftMethod,
          giftRule: row.giftRule,
          giftAlgorithm: row.giftAlgorithm,
          giftProducts: row.giftProducts.map(item => {
            return {
              ...item,
              skuList: undefined,
            };
          }),
        };
      });
      for (const row of rules) {
        let giftProduct: IGiftProductParam;
        if (row.giftProducts.length === 0) {
          Dialog.warning({
            title: '验证错误',
            content: `“${row.productName}” 的赠品不能为空`
          });
          return;

        }
        for (giftProduct of row.giftProducts) {
          if (!giftProduct.giftProductId || !giftProduct.giftProductName) {
            Dialog.warning({
              title: '验证错误',
              content: `请选择 “${row.productName}” 的赠品`
            });
            return;
          }
          if (!giftProduct.giftSkuId || !giftProduct.giftSkuName) {
            Dialog.warning({
              title: '验证错误',
              content: `请选择 “${row.productName}” 的规格`
            });
            return;
          }
          if (giftProduct.fullNumber === '' || giftProduct.fullNumber === undefined) {
            Dialog.warning({
              title: '验证错误',
              content: `“${row.productName}” 的满足条件不能为空`,
            });
            return;
          }
          if (isNaN(Number(giftProduct.fullNumber)) || Number(giftProduct.giftCount) < 0) {
            Dialog.warning({
              title: '验证错误',
              content: `“${row.productName}” 的满足条件错误，请填写合法的数字且必须大于等于0`,
            });
            return;
          };
          if (isNaN(giftProduct.giftCount) || Number(giftProduct.giftCount) < 1) {
            Dialog.warning({
              title: '验证错误',
              content: `“${row.productName}” 的赠送数量错误，请填写合法的数字且必须大于等于1`,
            });
            return;
          };
          // 赠送原品
          if (row.giftRule === 'SELF') {
            continue;
          }
          // 指定赠品必须选择商品
          if (!giftProduct.giftProductId) {
            Dialog.warning({
              title: '验证错误',
              content: `请选择 “${row.productName}” 的赠品`
            });
            return;
          }
        }
      }
    }
    const { activityName, startTime, endTime } = values;

    if (this.props.activityType === 'DISCOUNT') {
      api = apis['activity.discount.create']
      rules = dataSource.map((item: any) => ({productId: item.productId, discount: item.discount, productName: item.productName}));
    }
    if (this.props.activityType === 'SPECIAL') {
      api = apis['activity.special.create'];
      for (const item of dataSource as any) {
        const rule = rules.find(r => r.productId === item.productId);
        if (rule) {
          rule.dtls = [...rule.dtls, {price: item.price, skuId: item.skuId, skuName: item.skuName}];
        } else {
          rules.push({
            productId: item.productId,
            productName: item.productName,
            dtls: [{price: item.price, skuId: item.skuId, skuName: item.skuName}]
          });
        }
      }
    }

    Dialog.confirm({
      title: '提交确认',
      content: '请确认填写信息是否正确，提交后活动信息不可修改！',
      okText: '确认提交',
      cancelText: '再看看',
      onOk: () => {
        api.post({
          activityName,
          activityType: this.props.activityType,
          startDatetime: (startTime).startOf('minute').format('YYYY-MM-DD HH:mm:ss'),
          endDatetime: endTime.endOf('minute').format('YYYY-MM-DD HH:mm:ss'),
          rules,
          withCoupon: this.withCoupon ? 1 : 0,
        })
        .then(() => {
          showSuccess.save();
          if (callback) {
            callback();
          } else {
            this.props.history.close();
          }
        })
      }
    });
  }

  // 保存
  public clickSave = () => {
    this.validate(() => {
      this.props.history.close();
    });
  }

  // 保存并新增
  public clickSaveAndCreate = () => {
    this.validate(() => {
      this.resetFields();
    });
  }
}
